import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutJSON from "../content/about.json"
import "animate.css/animate.min.css";
import "../styles/bootstrap.scss"

export default function about() {
    return (
        <Layout>
            <Seo title="About Us" />
            <img className="parallax" src="/hard-drive.jpg" alt="Parallax" />
            <div className="banner-cover" />
            <div className="banner animate__animated fadeInDownSmall">
                <h1 className="display-3"><b>{AboutJSON.title}</b></h1>
            </div>
            <Container fluid className="bg-white" style={{padding: '5% 0 0', boxShadow: 'inset 0 5px 5px  #bebebe'}}>
                <Container className="mt-0">
                    <h6>{AboutJSON.subtitle.toUpperCase()}</h6>
                    <p>TRABUS is a minority-owned, Service-Disabled Veteran-Owned Small Business &#40;SDVOSB&#41; headquartered in San Diego, California with a regional office in the National Capitol Region. Incorporated in 2010, TRABUS began initially providing professional support services to the U.S. government.  As the company grew, TRABUS gained expertise and  began specializing in the development of technical solutions across five major portfolios: <b>Wireless Technologies</b>, <b>Artificial Intelligence & Data Science</b>, <b>Cybersecurity</b>, <b>Maritime Transportation</b>, and <b>Environmental Informatics</b>. The TRABUS workforce is diverse.  Key scientists and engineers are at the doctorate level.  A good portion of the TRABUS staff are veterans who have served in the military in positions up to major command.  Over 80% of our management personnel and remaining staff have certifications in their respective functional disciplines including engineering, cybersecurity, project management, and contracting.
                    <br/><br/>
                    In 2022, TRABUS was recognized as one of Southern California&lsquo;s Cool Companies by San Diego Connect and was featured in San Diego&lsquo;s first annual innovation day—Five.Ten.Thirty— as one of the fastest growing and most exciting Tech & Life Sciences companies in the region. Also in 2022, the Northern Virginia Chamber of Commerce named Trabus Technologies Innovator of the Year for the development of a ground-breaking wireless innovation called SEPP. This spectrally efficient peer-to-peer technology allows for simultaneous wireless conversations on the same frequency without the need for cell towers or infrastructure. TRABUS&lsquo; other best-known innovation, RippleGo, is a state-of-the-art information-sharing solution for the maritime transportation industry that provides up-to-date information on travel time predictions, bridge clearance calculations, forecasted river level conditions, navigation hazards and lock status changes using data analytics and artificial intelligence. For more information on these and other innovations visit the Innovations page on our website, www.trabus.com.

                    </p>
                    <Row className="d-flex justify-content-center align-items-center text-center mt-5">
                        <Col><img className="img-awards" src="/about/opt-inc.png" alt="Inc 5000"/></Col>
                        <Col><img className="img-awards" src="/about/honor-roll.png" alt="Honer Roll" /></Col>
                        <Col><img className="img-awards" src="/about/cool_company.png" alt="Cool Company" /></Col>
                        <Col><img className="img-awards" src="/about/innovation_badge.png" alt="Innovation Winning Badge" /></Col>
                    </Row>
                    <Row className='mt-4 mb-2'>
                        <Col className='d-flex justify-content-center'><img className="img-awards" src="/about/sdvosb.png" alt="SDVOSB" /></Col>
                        <Col className='d-flex justify-content-center'><img className="img-awards" src="/about/MBE.png" alt="MBE" /></Col>
                        <Col className='d-flex justify-content-center'><img className="img-awards" src="/about/DVBE.png" alt="DVBE" /></Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col className='d-flex justify-content-center'><img className="img-awards dgs" src="/about/dgs.jpeg" alt="DGS" /></Col>
                    </Row>
                </Container>
                <Container fluid className="py-5 bg-light" style={{padding: "0 15%", boxShadow: '0px 0px 10px 0px rgb(0 0 0 / 50%) inset'}}>
                    <Row className="d-flex justify-content-around mt-5">
                        {AboutJSON.info.map( category => (
                            <Col lg={5} key={category.title}>
                                <h5>{category.title.toUpperCase()}</h5>
                                <ul>
                                    {category.items.map( (list, index) => (
                                        <li key={index}>{list}</li>
                                    ))}
                                </ul>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
        </Layout>
    )
}
